export const menuItems = [
 
    {
        id: 1,
        label: "Dashboard",
        icon: "uil-home-alt",
        link: "/dashboard"
    },
 
    {
        id: 2,
        label: "Restaurant",
        icon: "uil-restaurant",
        subItems: [
            {
                id: 1,
                label: "Create",
                link: "/create-restaurant",
                parentId: 2
            },
            {
                id: 2,
                label: "Listing",
                link: "/restaurant-listing",
                parentId: 2
            },
         
        ]
    },
    
    {
        id: 4,
        label: "My Profile",
        icon: "mdi mdi-account-outline",
        link: "/my-profile"
    },
    
]