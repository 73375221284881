<script>
import {layoutComputed} from "@/state/helpers";
import Vertical from "./vertical";

/**
 * Main Layout
 */
export default {
    components: {
        Vertical,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {},
};
</script>

<template>
<div>
    <Vertical :layout="layoutType">
        <slot />
    </Vertical>

   
</div>
</template>
