<script>
import simplebar from "simplebar-vue";
import {authMethods} from "@/state/helpers";
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      text: null,
      notifications:[],
      unread_notifications_count:0,
    };
  },
  mounted() {
    this.getNotifications();
    this.getUnreadNotificationsCount();
  },

  methods: {
    ...authMethods,
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
   
    logoutUser() {
      this.logOut();
    },
    // :src="currentUser.avatar==null?'@/assets/images/users/default-avatar.jpg':currentUser.avatar"
    getNotifications(){
      
        this.$axios.get("/user/notifications").then((response) => {
          this.notifications=response.data.payload;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    getUnreadNotificationsCount(){
        this.$axios.get("/user/get-unread-notifications").then((response) => {
        this.unread_notifications_count=response.data.payload;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    readNotifications(){
      
        this.$axios.get("/user/read-all-notifications").then((response) => {
        this.notifications=response.data.payload;
          this.getUnreadNotificationsCount();
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    readNotification(notification){
      if(notification.read_at==null)
      {
        this.$axios.get("/user/read-notification",{params:{id:notification.id}}).then((response) => {
        this.getNotifications();
        this.getUnreadNotificationsCount();
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
      }
        let action =notification.data.action==null ? '/' : notification.data.action ;
        if (this.$route.path !== action) {
          this.$router.push(action);
        }
    },
    clearAllNotification(){
      this.$axios.get("/user/delete-all-notifications").then((response) => {
      this.notifications=[];
        this.getUnreadNotificationsCount();
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });

    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="20" />
            </span>
          </router-link>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="uil-search"></span>
          </div>
        </form> -->
      </div>

      <div class="d-flex">
        <b-dropdown
          variant="white"
          class="d-inline-block d-lg-none ms-2"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-search"></i>
          </template>
          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('navbar.search.text')"
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="uil-minus-path"></i>
          </button>
        </div>

        <b-dropdown
          variant="white"
          class="dropdown d-inline-block"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-bell"></i>
            <span class="badge border border-warning border-white f-12 p-1 primaryColorBg rounded-circle w-50">{{unread_notifications_count}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h5>
              </div>
              <div class="col-auto">
                <a @click="readNotifications" href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px" data-simplebar>
            <div v-for="(notification ,index) in notifications" :key="index">
             <a class="text-reset notification-item"  href="javascript:void(0)" @click="readNotification(notification)">
              <div class="media" :class="{'bg-body' : notification.read_at==null}">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ notification.data.subject}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                    {{ notification.data.message}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ formattedTime(notification.created_at)}}
                    </p>
                  </div>
                </div>
              </div>
             </a>
            </div>
            
          </simplebar>
          <div class="p-2 border-top">
            <div class="d-grid text-center">
               <router-link class="btn btn-sm btn-link font-size-14 " :to="isTeamMember() ? '/team-member-notifications' :'/notifications'">
               
                <i class="uil-eye me-1"></i>
                 View all 
               </router-link>
            
            </div>
          </div>
        </b-dropdown>

        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <span
              class="d-xl-inline-block ms-1 fw-medium font-size-15"
              >{{ currentUser.name }}</span>
            <img
              class="rounded-circle header-profile-user"
              :src="currentUser.avatar==null ? currentUser.avatar_path : assets_url + currentUser.avatar"
              alt="Avatar"
            />
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          
          <a class="dropdown-item" href="/my-profile"  v-if="currentUser.type == 'restaurant_owner' || currentUser.type == 'manager'">
            <i
              class="uil uil-user-circle font-size-18 align-middle text-muted me-1"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.profile")
            }}</span>
          </a>

          
           <a class="dropdown-item" href="/employee-profile" v-else>
            <i
              class="uil uil-user-circle font-size-18 align-middle text-muted me-1"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.profile")
            }}</span>
          </a>
          
          <a class="dropdown-item" style="cursor: pointer;" @click="logoutUser">
            <i
              class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.logout")
            }}</span>
          </a>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>
